// ロールオーバーで画像が切り替わるように、
// img要素の隣にimgを配置
$(document).ready(function() {

    $(".roll_over_img").each(function(i) {
        const out_img = $("img:first-of-type", this);
        const src_split = out_img.attr("src").split("out");
        var img;
        if( src_split[1] == undefined ){
            img = $("<img>", {
                src: out_img.attr("src"),
            });
        }else{
            const src = {
                prefix: src_split[0],
                suffix: src_split[1],
            };
            img = $("<img>", {
                src: `${src.prefix}over${src.suffix}`,
            });
        }
        $(img).css('pointer-events', 'none');
        $(out_img).parent().append(img);
    });

});
