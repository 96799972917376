$(document).ready(function () {


    $(function () {

        const $nav = $('.drop_down_nav');
        $('li ul', $nav).hide();

        $('li', $nav).hover(function () {
            $("ul:not(:animated)", this).fadeIn('fast');
        }, function () {
            $("ul", this).fadeOut('fast');
        });

        // スマホ端末の場合は、タップでドロップダウン出現
        if (isMobile()) {
            $(">li", $nav).each(function(i, elm) {
                if($(elm).children(".drop_down").length === 0) return true;
                $(">a", $(elm)).attr("href", "javascript:void(0)");
                $("li", $nav).on("touchstart", function () {
                    $("ul:not(:animated)", this).fadeIn('fast');
                });
            });
        }
    });

});