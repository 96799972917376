
$(document).ready(function() {


    const nortices = $("#notice-contents .notice-list a");
    const close_btn = $("#close-btn");


    $(function () {

        nortices.on("click", function () {
            return onNortices(this);
        });

        close_btn.on("click", function () {
            return onCloseBtn(this);
        });
    });


    function onNortices(own) {

        const is_more = $("#notice-contents").hasClass("is_more");
        const scroll = g_scroll;
        const page_scroll = $(window).scrollTop();
        // const tab = $("#tabs .tab_item:not(.inactive)").index() + 1;

        location.href = `information.html` +
            `?id=${own.id}` +
            `&is_more=${is_more}` +
            `&scrl=${scroll}` +
            `&page_scr=${page_scroll}`;

        return false;
    };


    function onCloseBtn(own) {
        const query = location.search;
        const is_more = getParam("is_more", query);
        const scrl = getParam("scrl", query);
        const page_scroll = getParam("page_scr", query);
        // const tab = getParam("tab", query);

        location.href = `index.html` +
            `?is_more=${is_more}` +
            `&scrl=${scrl}` +
            `&page_scr=${page_scroll}`;

        return false;
    }
});