$(document).ready(function () {

    const scr = $.cookie("page_scr") || 0;
    $(window).scrollTop(scr);

    $(window).on("beforeunload", function () {
        const scr = $(window).scrollTop();
        $.cookie("page_scr", scr);
    });

});