
$(document).ready(function() {
    const nt_a = $("a.hover_nt");    // a要素を取得
	const img = $("img", nt_a);      // img要素を取得
    
	try {
        /* Alphapickerにimg要素を渡し、Alphapickerオブジェクトを取得 */
        img.each((i, elm) => {
            console.log($(elm));
            elm.alpha = AlphaPicker(elm);
        })
	}
	catch(e) {
		/* エラー処理 */
		console.log(e.name + ":" + e.message);
		return;
	}
	
	/* a要素のイベントを無効にします */
	nt_a.addClass("alphapicker");
	nt_a.each((i, elm) => {
        elm.addEventListener("click", function(e) {
            e.preventDefault();
        }, false);
        const data = {};
        data.url = $(elm).attr('href');
        data.target = $(elm).attr('target');
        img[i].move_data = data;
    })
	
    /* Alphapickerオブジェクトのメソッドでマウスオーバーとリンクを設定します */
    img.each((i, elm) => {
        elm.alpha.mousemove(function(e) {
            console.log(e.alpha);
            // console.log(e.alpha);
            /* イベントオブジェクトのalphaプロパティに、マウス座標のアルファ値が入っています */
            if(e.alpha > 50) {
                $(this).addClass("hover");
            } else {
                $(this).removeClass("hover");
            }
        }).mouseout(function(e) {
            $(this).removeClass("hover");
        }).click(function(e) {
            if(e.alpha > 50) {
                switch(this.move_data.target) {
                    case "_blank":
                        window.open(this.move_data.url);
                        break;
                    default: {
                        location.href = this.move_data.url;
                        break;
                    }
                }
            }
        });
    })
});