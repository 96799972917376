function LOG(msg) {
    console.log(msg);
}


function random(_range, _ofs = 0) {
    return Math.floor(Math.random() * _range) + _ofs;
}


function zeroPadding(num, length) {
    return ('0000000000' + num).slice(-length);
}


function isString(check) {
    return typeof (check) == "string" || check instanceof String;
}


function isMobile() {
    var regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return (window.navigator.userAgent.search(regexp) !== -1);
}


/**
 * Get the URL parameter value
 *
 * @param  name {string} パラメータのキー文字列
 * @return  url {url} 対象のURL文字列（任意）
 */
function getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}



/*****************************************************************
/*
/* 起動時処理
/*
/*****************************************************************/

/*****************************************************************
/*
/* メニュー処理
/*
/*****************************************************************/

/*****************************************************************
/*
/* 起動時エラーメッセージ
/*
/*****************************************************************/
$(document).ready(function () {

    if ($.cookie("ErrorMessage") != null &&
        $.cookie("ErrorMessage") != undefined
    ) {
        alert($.cookie("ErrorMessage"));
        $.cookie("ErrorMessage", null);
    }

});


/*****************************************************************
/*
/* ポップアップ表示用
/*
/*****************************************************************/
function popup(path, name, width, height, position, scrollbars, resizable) {

    if (typeof (position) == "undefined") {
        position = "center";
    }

    if (typeof (scrollbars) == "undefined") {
        scrollbars = "no";
    }

    if (typeof (resizable) == "undefined") {
        resizable = "yes";
    }

    if (position == "center") {
        lf = (window.screen.availWidth - width) / 2;
        tp = (window.screen.availHeight - height) / 2;
    } else if (position == "right") {
        lf = window.screen.availWidth - width - 10;
        tp = 0;
    } else {
        lf = 0;
        tp = 0;
    }

    window.open("/popup.html?src=" + path, name, "top=" + tp + ",left=" + lf + ",width=" + width + ",height=" + height + ",resizable=" + resizable + ",scrollbars=" + scrollbars + ",status=no,toolbar=no,directories=no,menubar=no");
}


/*****************************************************************
/*
/* ソーシャルブックマークの登録用
/*
/*****************************************************************/
function addSocialBookmark(SMB, Link, Title) {

    var title;
    var link;
    var open_url;

    // タイトルの指定が無ければ現在のHTMLのタイトルを使用
    if (Title != undefined || Title == "") {
        title = encodeURIComponent(Title);
    } else {
        title = encodeURIComponent(document.title);
    }

    // リンクの指定が無ければ現在のホスト名を利用
    if (Link != undefined || Link == "") {
        link = encodeURIComponent(Link);
    } else {
        link = encodeURIComponent("http://" + window.location.hostname + "/");
    }


    switch (SMB) {
        case "Yahoo":
            open_url = "http://bookmarks.yahoo.co.jp/bookmarklet/showpopup" +
                "?t=" + title +
                "&u=" + link +
                "&opener=bm&ei=UTF-8";
            break;

        case "Google":
            open_url = "http://www.google.com/bookmarks/mark" +
                "?op=edit" +
                "&title=" + title +
                "&bkmk=" + link;

            break;

        case "Hatena":
            open_url = "http://b.hatena.ne.jp/append" +
                "?" + link;
            break;

        case "Nifty":
            open_url = "http://clip.nifty.com/create" +
                "?title=" + title +
                "&url=" + link;
            break;


        case "livedoor":
            open_url = "http://clip.livedoor.com/redirect" +
                "?title=" + title +
                "&link=" + link;
            break;


        case "delicious":
            open_url = "http://del.icio.us/post" +
                "?title=" + title +
                "&url=" + link;
            break;


        default:
            open_url = ""
            break;
    }

    window.open(open_url);
    //alert(open_url);
}


/*****************************************************************
/*
/* ブックマークの登録用
/*
/*****************************************************************/
function addBookmark(URL, Title) {
    try {
        window.external.AddFavorite(URL, Title);
    } catch (e) {
        alert("このリンクはIE(Internet Exploler)専用です");
    }
}

/*****************************************************************
/*
/* 動きのあるTOPに戻る用
/*
/*****************************************************************/
$(document).ready(function () {

    $("#to_top_btn").on("click", function () {

        $("html, body").animate({
            scrollTop: 0
        }, 500, "easeOutQuart");
    })
})


/*****************************************************************
/*
/* ブラウザ強制閉じスクリプト
/*
/*****************************************************************/

function WindowForceClose() {

    window.opener = window;
    window.open(location.href, '_self');
    window.close();

}


/*****************************************************************
/*
/* XHTMLの為のIframe動的呼び出し
/*
/*****************************************************************/

function ShowIframe(Width, Height, Src, ID, Text, Scrolling) {

    if (typeof (Width) == "undefined" || Width == "") {
        Width = "100%";
    }

    if (typeof (Height) == "undefined" || Height == "") {
        Height = "100%";
    }

    if (typeof (Text) == "undefined" || Text == "") {
        Text = "ここはインラインフレームです";
    }

    if (typeof (Scrolling) == "undefined" || Scrolling == "") {
        Scrolling = "no";
    }

    var tag = "<iframe id='" + ID + "' src='" + Src + "' frameborder='0' width='" + Width + "' height='" + Height + "' scrolling='" + Scrolling + "'>" +
        Text +
        "</iframe>";

    document.write(tag);

}