// moreボタン関係
$(document).ready(function () {


    const $more_btn = $("#more-btn");
    const $back_btn = $("#back-btn");
    const $to_top_btn = $("#to-top-btn");
    const $contents = $("#notice-contents");


    $(function () {

        initButtonDisp();
        initMoreButton();


        const query = location.search;

        // 前のページから戻ってきて、more、スクロール等が操作されていたら
        if (getParam("is_more", query) === "true") {
            onMoreButton(false);
            const scr = Number(getParam("scrl"));
            $contents.mCustomScrollbar("scrollTo", scr, {
                scrollInertia: 0,
            });
        }

        // moreボタン押したら
        $more_btn.click(function () {
            return onMoreButton();
        });
        // 戻るボタン押したら
        $back_btn.click(function () {
            return onBackButton();
        });
        // 一覧トップへボタン
        $to_top_btn.click(function () {
            return onToTopButton();
        });
    });


    /*
     * Moreボタン押下時
     */
    function onMoreButton(is_anim = true) {

        $more_btn.addClass("disable");
        $to_top_btn.removeClass("hide");
        $back_btn.removeClass("disable");
        $contents.addClass("is_more");

        const anim_speed = "slow";
        const target = $(".contents_more-area, .contents_more-area ~ li:not(.contents_exclusion)");
        // 表示対象をアニメーション効果と共に表示
        // $(target).slideDown(`${anim_speed}`, () => {
        //     $("#notice-contents").addClass("of_scroll-y");
        // });
        if (is_anim) {
            let once = false;
            $(target).show(`${anim_speed}`, () => {
                if (once) return;
                once = true;
                // $("#notice-contents").addClass("of_scroll-y");
                createScrollbar();
            });
        } else {
            $(target).show();
            createScrollbar();
            // var ps = new PerfectScrollbar('#notice-contents.contents');
        }
        // $("#notice-contents").addClass("of_scroll-y");

        return false; // リンクとして機能しないようfalseを返す
    };


    /*
     * Backボタン押下時
     */
    function onBackButton() {
        $contents.removeClass("is_more");

        // スクロール位置をもとに戻す
        $contents.scrollTop(0);

        closeAction();
        // initMoreButton("#tabs .tab_item:not(.inactive)");

        return false;
    };


    /*
     * トップへボタン
     */
    function onToTopButton() {
        $contents.mCustomScrollbar("scrollTo", "top", {
            scrollInertia: 700,
            scrollEasing: "easeOut",
        });

        return false;
    };


    /*
     * moreボタンを表示するか　
     */
    function initMoreButton() {

        // スクロールバー削除
        deleteScrollbar();

        // 初期表示件数の最大数
        const notice_max = 15;
        // const notice_exclusion = 15;
        const notice_items = $("#notice-contents .notice-list li");
        notice_items.each(function (i) {
            if (i !== notice_max) return true;
            $(this).addClass("contents_more-area");
            $more_btn.removeClass("disable hide");
            return false;
        });

        // 6件目以降は隠す
        $("#notice-contents .contents_more-area, .contents_more-area ~ li").hide();
    };


    /*
     * 表示領域を狭める処理
     */
    function closeAction() {

        $("#btn-wrap").addClass("hide");

        // スクロールバー削除
        deleteScrollbar();

        let done = false;
        $contents.find(".contents_more-area, .contents_more-area ~ li")
            .hide("slow", function () {
                if (done) return;
                done = true;
                $more_btn.removeClass("disable");
                $to_top_btn.addClass("hide");
                $back_btn.addClass("disable");
                $("#btn-wrap").removeClass("hide");
            });
    }


    /*
     * 各種ボタンの表示状態を初期化
     */
    function initButtonDisp() {
        $more_btn.addClass("hide");
        $to_top_btn.addClass("hide");
        $back_btn.addClass("disable");
    };


    /*
     * スクロールバー作成
     * プラグインのCustomScrollbarを使用
     */
    function createScrollbar() {
        $contents.mCustomScrollbar({
            scrollInertia: 560, //スクロール加速度 OSデフォルトと異なると使いにくいので0
            mouseWheelPixels: 100, //スクロール量 デフォルトに近づけた。お好みで変更
            autoHideScrollbar: false, //マウスオーバーしていないときにスクロールバーを隠すか お好みで
            alwaysShowScrollbar: 2,
            mouseWheel: {
                preventDefault: false,
            },

            theme: "3d",

            callbacks: {
                whileScrolling: function () {
                    g_scroll = this.mcs.top;
                },
            },
        });
    };


    /*
     * スクロールバー削除
     */
    function deleteScrollbar() {
        $contents.mCustomScrollbar("destroy");
    }
});